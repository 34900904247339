import dayjs from "dayjs";
import { DatevTransfer } from "./DatevTransfer";
import { IncomingBill } from "./IncomingBill";
import { IncomingCreditNote } from "./IncomingCreditNote";
import { Supplier } from "./Supplier";

export abstract class IncomingReceipt {
    public archived: boolean;
    public createdAt: string;
    /**
     * Date String in Format "YYYY-MM-DD"
     */
    public date: string;
    public datevTransfer: DatevTransfer | null;
    public id: string;
    public receiptNumber: string | null;
    public recipient: IncomingReceiptPersonalInformation;
    public sender: IncomingReceiptPersonalInformation;
    public storagePath: string;
    public supplierId: Supplier["id"] | null;
    readonly _searchableSubstrings: string[] = [];

    constructor(initialValues?: Partial<IncomingReceipt>) {
        this.archived = initialValues?.archived ?? false;
        this.createdAt = initialValues?.createdAt ?? new Date().toISOString();
        this.date = initialValues?.date ?? dayjs().format("YYYY-MM-DD");
        this.datevTransfer = initialValues?.datevTransfer ?? null;
        this.id = initialValues?.id ?? "";
        this.receiptNumber = initialValues?.receiptNumber ?? null;
        this.recipient = initialValues?.recipient ?? { name: "", address: "", email: "" };
        this.sender = initialValues?.sender ?? { name: "", address: "", email: "" };
        this.storagePath = initialValues?.storagePath ?? "";
        this.supplierId = initialValues?.supplierId ?? null;
        this._searchableSubstrings = initialValues?._searchableSubstrings ?? [];
    }
}
type IncomingReceiptPersonalInformation = {
    name: string;
    address: string;
    email: string;
};

export enum IncomingReceiptType {
    INCOMING_BILL = "INCOMING_BILL",
    INCOMING_CREDIT_NOTE = "INCOMING_CREDIT_NOTE",
}

export type AnyIncomingReceipt = IncomingBill | IncomingCreditNote;
export type Vat = {
    amount: number;
    rate: number;
    occurrence: number;
};

export type LineItem = {
    amount: number;
    description: string;
    quantity: number;
    unitPrice: number;
    unit: string;
};
