import dayjs from "dayjs";
import { CostAccount } from "./CostAccount";
import { Currency } from "./Currency";
import { IncomingReceipt, LineItem, Vat } from "./IncomingReceipt";
import { OperatingUnit } from "./OperatingUnit";

export class IncomingCreditNote extends IncomingReceipt {
    public costAccountId: CostAccount["id"] | null;
    public currency: Currency;
    public dueDate: string;
    public grossAmount: number;
    /**
     * format: ISO datetime
     */
    public csvExportDate: string | null;
    public lineItems: LineItem[];
    public netAmount: number;
    public operatingUnitId: OperatingUnit["id"];
    public totalTaxAmount: number;
    public paymentDate: string | null;
    public status: IncomingCreditNoteStatus;
    public vat: Vat[];

    constructor(initialValues?: Partial<IncomingCreditNote>) {
        super(initialValues);
        this.costAccountId = initialValues?.costAccountId ?? null;
        this.currency = initialValues?.currency ?? Currency.EUR;
        this.date = initialValues?.date ?? dayjs().format("YYYY-MM-DD");
        this.dueDate = initialValues?.dueDate ?? "";
        this.grossAmount = initialValues?.grossAmount ?? 0;
        this.csvExportDate = initialValues?.csvExportDate ?? null;
        this.lineItems = initialValues?.lineItems ?? [];
        this.netAmount = initialValues?.netAmount ?? 0;
        this.operatingUnitId = initialValues?.operatingUnitId ?? "";
        this.totalTaxAmount = initialValues?.totalTaxAmount ?? 0;
        this.paymentDate = initialValues?.paymentDate ?? null;
        this.vat = initialValues?.vat ?? [];
        this.status = initialValues?.status ?? IncomingCreditNoteStatus.OPEN;
    }
}

export function isIncomingCreditNote(receipt: any): receipt is IncomingCreditNote {
    return (
        typeof receipt.grossAmount === "number" &&
        typeof receipt.netAmount === "number" &&
        typeof receipt.paymentInstruction === "undefined"
    );
}

export enum IncomingCreditNoteStatus {
    OPEN = "IncomingCreditNoteStatus_OPEN",
    PAID = "IncomingCreditNoteStatus_PAID",
}

export function getIncomingCreditNoteStatusText(status: IncomingCreditNoteStatus): string {
    switch (status) {
        case IncomingCreditNoteStatus.OPEN:
            return "Offen";
        case IncomingCreditNoteStatus.PAID:
            return "Bezahlt";
    }
}
