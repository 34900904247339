import dayjs from "dayjs";
import { CostAccount } from "./CostAccount";
import { Currency } from "./Currency";
import { IncomingReceipt, LineItem, Vat } from "./IncomingReceipt";
import { OperatingUnit } from "./OperatingUnit";

export class IncomingBill extends IncomingReceipt {
    public costAccountId: CostAccount["id"] | null;
    public currency: Currency;
    public dueDate: string;
    public grossAmount: number;
    /**
     * format: ISO datetime
     */
    public csvExportDate: string | null;
    public lineItems: LineItem[];
    public netAmount: number;
    public operatingUnitId: OperatingUnit["id"];
    public totalTaxAmount: number;
    public vat: Vat[];
    public paymentDate: string | null;
    public status: IncomingBillStatus;
    paymentInstruction: PaymentInstruction | null;

    constructor(initialValues?: Partial<IncomingBill>) {
        super(initialValues);
        this.costAccountId = initialValues?.costAccountId ?? null;
        this.currency = initialValues?.currency ?? Currency.EUR;
        this.date = initialValues?.date ?? dayjs().format("YYYY-MM-DD");
        this.dueDate = initialValues?.dueDate ?? "";
        this.grossAmount = initialValues?.grossAmount ?? 0;
        this.csvExportDate = initialValues?.csvExportDate ?? null;
        this.lineItems = initialValues?.lineItems ?? [];
        this.netAmount = initialValues?.netAmount ?? 0;
        this.operatingUnitId = initialValues?.operatingUnitId ?? "";
        this.totalTaxAmount = initialValues?.totalTaxAmount ?? 0;
        this.vat = initialValues?.vat ?? [];
        this.paymentInstruction = initialValues?.paymentInstruction ?? null;
        this.paymentDate = initialValues?.paymentDate ?? null;
        this.status = initialValues?.status ?? IncomingBillStatus.OPEN;
    }
}

export function isIncomingBill(receipt: any): receipt is IncomingBill {
    const receiptToCheck = receipt as IncomingBill;
    return (
        typeof receiptToCheck.grossAmount === "number" &&
        typeof receiptToCheck.netAmount === "number" &&
        typeof receiptToCheck.paymentInstruction === "object"
    );
}

type PaymentInstruction = {
    iban: string;
    bankName: string;
    remittanceInformation: string;
};

export enum IncomingBillStatus {
    OPEN = "IncomingBillStatus_OPEN",
    PAID = "IncomingBillStatus_PAID",
}

export function getIncomingBillStatusText(status: IncomingBillStatus) {
    switch (status) {
        case IncomingBillStatus.OPEN:
            return "Offen";
        case IncomingBillStatus.PAID:
            return "Bezahlt";
    }
}
