import { immerable } from "immer";
import { BillReminderSettings, DEFAULT_BILL_REMINDER_SETTINGS } from "./BillReminder";
import { BillSettings } from "./BillSettings";
import { CountryCode } from "./CountryCode";
import { CreditNoteSettings } from "./CreditNoteSettings";
import { CustomerPaymentInfos, DEFAULT_CUSTOMER_PAYMENT_INFOS } from "./Customer";
import { DatevProperties, createDefaultDatevProperties } from "./DatevProperties";
import { DeliveryNoteSettings } from "./DeliveryNoteSettings";
import { OfferSettings } from "./OfferSettings";
import { IdentifiablePhoto } from "./Order";
import { Rounding } from "./Rounding";
import { Vertex } from "./Vertex";

export class OperatingUnit {
    [immerable] = true;
    public archived: boolean;
    public bankAccounts: BankAccount[];
    public connectedBanksNeedAttention: boolean;
    public billSettings: BillSettings;
    public billReminderSettings: BillReminderSettings;
    public city: string;
    /** DE: Betriebsnummer */
    public companyNumber: string;
    public countryCode: CountryCode;
    public creditNoteSettings: CreditNoteSettings;
    public customerPaymentInfos: CustomerPaymentInfos;
    public datev: DatevProperties;
    public deliveryNoteSettings: DeliveryNoteSettings;
    public email: string;
    public fax: string;
    public id: string;
    public latLng?: Vertex;
    public legalInfo: string;
    public mailSettings: OperatingUnitMailSettings;
    public logo: IdentifiablePhoto | null;
    public receiptLogoSettings: ReceiptLogoSettings;
    public mobile: string;
    public name: string;
    public notes: string;
    public offerSettings: OfferSettings;
    public rounding: Rounding;
    /** DE: Umsatzsteuer-ID */
    public salesTaxId: string;
    public state: string;
    public street: string;
    /** DE: Steueridentifikationsnummer (Steuer-ID) */
    public taxNumber: string;
    public telephone: string;
    public vat?: number;
    public website: string;
    public zip: string;

    constructor(initialValues?: Partial<OperatingUnit>) {
        this.archived = initialValues?.archived ?? false;
        this.bankAccounts = initialValues?.bankAccounts ?? [];
        this.connectedBanksNeedAttention = initialValues?.connectedBanksNeedAttention ?? false;
        this.billSettings = new BillSettings(initialValues?.billSettings);
        this.billReminderSettings = initialValues?.billReminderSettings ?? DEFAULT_BILL_REMINDER_SETTINGS;
        this.city = initialValues?.city ?? "";
        this.companyNumber = initialValues?.companyNumber ?? "";
        this.countryCode = initialValues?.countryCode ?? "DE";
        this.creditNoteSettings = new CreditNoteSettings(initialValues?.creditNoteSettings);
        this.offerSettings = new OfferSettings(initialValues?.offerSettings);
        this.receiptLogoSettings = initialValues?.receiptLogoSettings ?? {
            logoPosition: LogoPosition.RIGHT,
            offset: -10,
        };
        this.customerPaymentInfos = {
            ...DEFAULT_CUSTOMER_PAYMENT_INFOS,
            ...initialValues?.customerPaymentInfos,
        };
        this.datev = { ...createDefaultDatevProperties(), ...initialValues?.datev };
        this.deliveryNoteSettings = new DeliveryNoteSettings(initialValues?.deliveryNoteSettings);
        this.email = initialValues?.email ?? "";
        this.fax = initialValues?.fax ?? "";
        this.id = initialValues?.id ?? "";
        this.latLng = initialValues?.latLng;
        this.legalInfo = initialValues?.legalInfo ?? "";
        this.logo =
            initialValues?.logo && (!initialValues.logo.imageSrc || !initialValues.logo.storagePath)
                ? null
                : initialValues?.logo ?? null;
        this.mailSettings = {
            bill: initialValues?.mailSettings?.bill ?? getDefaultMailSettings(),
            deliveryNote: initialValues?.mailSettings?.deliveryNote ?? getDefaultMailSettings(),
            offer: initialValues?.mailSettings?.offer ?? getDefaultMailSettings(),
            creditNote: initialValues?.mailSettings?.creditNote ?? getDefaultMailSettings(),
            cancellationBill: initialValues?.mailSettings?.cancellationBill ?? getDefaultMailSettings(),
            billReminder: initialValues?.mailSettings?.billReminder ?? getDefaultMailSettings(),
            maschinenringExport:
                initialValues?.mailSettings?.maschinenringExport ??
                getDefaultOperatingUnitMailSettings().maschinenringExport,
        };
        this.mobile = initialValues?.mobile ?? "";
        this.name = initialValues?.name ?? "";
        this.notes = initialValues?.notes ?? "";
        this.offerSettings = new OfferSettings(initialValues?.offerSettings);
        this.rounding = initialValues?.rounding ?? { hours: null };
        this.salesTaxId = initialValues?.salesTaxId ?? "";
        this.state = initialValues?.state ?? "";
        this.street = initialValues?.street ?? "";
        this.taxNumber = initialValues?.taxNumber ?? "";
        this.telephone = initialValues?.telephone ?? "";
        this.vat = initialValues?.vat;
        this.website = initialValues?.website ?? "";
        this.zip = initialValues?.zip ?? "";
    }
}

export type BankAccount = {
    id: string;
    bankName?: string;
    iban?: string;
    bic?: string;
};

export type ReceiptLogoSettings = {
    logoPosition: LogoPosition;
    /**
     * Offset in mm
     * negativ left
     * positiv right
     * */
    offset: number;
};

export enum LogoPosition {
    LEFT = "LogoPosition_LEFT",
    CENTER = "LogoPosition_CENTER",
    RIGHT = "LogoPosition_RIGHT",
}

export type OperatingUnitMailSettings = {
    offer: MailSettings;
    bill: MailSettings;
    deliveryNote: MailSettings;
    creditNote: MailSettings;
    billReminder: MailSettings;
    cancellationBill: MailSettings;
    maschinenringExport: MaschinenringExportMailSettings;
};

export type MailSettings = {
    subject: string;
    text: string;
    displayName: string;
    replyTo: string;
    cc: string;
    bcc: string;
};

export type MaschinenringExportMailSettings = MailSettings & {
    withPersonalSalutation: boolean;
};

export function getDefaultOperatingUnitMailSettings(): OperatingUnitMailSettings {
    return {
        bill: getDefaultMailSettings(),
        deliveryNote: getDefaultMailSettings(),
        offer: getDefaultMailSettings(),
        creditNote: getDefaultMailSettings(),
        cancellationBill: getDefaultMailSettings(),
        billReminder: getDefaultMailSettings(),
        maschinenringExport: {
            ...getDefaultMailSettings(),
            withPersonalSalutation: true,
        },
    };
}

export function getDefaultMailSettings(): MailSettings {
    return {
        subject: "",
        text: "",
        displayName: "",
        replyTo: "",
        cc: "",
        bcc: "",
    };
}
